<template>
  <l-map style="width:100%; height:680px;" :zoom="zoom" :crs="crs" :center="center">
    <l-tile-layer :url="url" :subdomains="subdomains" :options="tileLayerOptions" :tms="true" />
    <l-marker :lat-lng="marker" :icon="addressIcon" :attribution="attribution" />
  </l-map>
</template>
 
<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet"
import { latLng, icon } from "leaflet"

import L from 'leaflet'
import 'proj4'
import 'proj4leaflet'
import 'leaflet.chinatmsproviders'
 
export default {
  components: { LMap, LTileLayer, LMarker },
  data() {
    return {
      zoom: 13,
      crs: L.CRS.EPSG3857,
      tms: false,
      center: latLng(34.697487, 135.531584),
      url: '//online{s}.map.bdimg.com/onlinelabel/?qt=tile&x={x}&y={y}&z={z}&styles=pl&scaler=1&p=1',
      subdomains: '0123456789',
      tileLayerOptions: {
        maxZoom: 18,
        minZoom: 8
      },
      marker: [],
      attribution: '&copy; <a href="http://www.baidu.com">Baidu</a>',
      addressIcon: icon({
        iconUrl: require('@/assets/images/address.png'),
        iconSize: [32, 32],
        iconAnchor: [16, 10]
      }),
    }
  },
  mounted() {
    this.crs = L.CRS.Baidu
    console.log('---this.crs', this.crs);
    this.tms = true
    this.marker = this.center
  }
}
</script>